@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

.container,
.fluid,
.xxl,
.xl,
.lg,
.md,
.sm {
  --gutter-x: 1.5rem;
  --gutter-y: 0;

  width: 100%;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: rem(525)) {
  .sm,
  .container {
    max-width: rem(540);
  }
}

@media (min-width: $mq-desktop-md) {
  .md,
  .sm,
  .container {
    max-width: rem(720);
  }
}

@media (min-width: $mq-desktop-l) {
  .lg,
  .md,
  .sm,
  .container {
    max-width: rem(1175);
  }
}

@media (min-width: $mq-desktop-xl) {
  .xl,
  .lg,
  .md,
  .sm,
  .container {
    max-width: rem(1300);
  }
}
