@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.critizr {
  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  display: flex;
  align-items: center;
  margin: 0 auto;
  color: $gray-light-2;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $gray-medium-2;
    text-decoration: none;
  }
}

.rating {
  @include mobile {
    width: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.ratingText {
  padding-top: rem(2);
  margin-left: rem(10);
  font-weight: 300;
  font-size: rem(12);
  white-space: nowrap;

  b {
    font-weight: 600;
  }
}

.minimizedRatingText {
  text-decoration: underline;
}

.ratingTextBrown {
  color: $brown;
}

.ratingTextDark {
  color: $dark-4;
}

.ratingStars {
  line-height: rem(14);
}

.infos {
  @include mobile {
    padding-left: 0;
    font-size: rem(11);
    line-height: rem(13);
  }

  display: flex;
  align-items: center;
  padding-left: rem(20);
  font-weight: 300;
  font-size: rem(13);
}

.infosLogo {
  width: rem(90);
  height: rem(25);
  padding: rem(5) rem(10);
  border-radius: rem(90);
  background-color: rgb(255 255 255 / 72%);
}

.infosLogoAvisVerifie {
  padding: rem(5);
}

.infosText {
  @include mobile {
    &:last-child {
      width: rem(162);
    }
  }

  margin-left: rem(20);
}

.modal :global(.modal-dialog) {
  height: 100%;
}

.modal :global(.modal-content) {
  height: 80%;
}

.condensedCritizr {
  display: block;
  font-size: rem(32);
  text-align: center;
}
