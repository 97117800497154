@import 'abstracts/variables';

.showMoreDescription {
  position: relative;
  overflow: hidden;
  display: block;
  height: auto;
  white-space: pre-line;
}

.showMoreReadMore {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 15%;
  margin-bottom: 0;
  // stylelint-disable-next-line color-function-notation
  background-image: linear-gradient(to bottom, rgba($light, 40%), $light);
}

.showMoreBtnReadMore {
  display: flex;
  align-items: center;
  border: 0;
  margin: auto;
  color: $brown;
  font-weight: 700;
  background-color: transparent;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    border: 0;
    color: $brown !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}

.showMoreBtnReadMore:hover {
  color: $brown;
}
