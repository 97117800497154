@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.title1 {
  @include mobile {
    font-size: rem(22);
    line-height: rem(27);
  }

  @include desktop {
    font-size: rem(28);
    line-height: rem(33);
  }

  color: $red;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  h1 {
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
