@import 'abstracts/mixins';

.fullDimensions {
  width: 100%;
  height: 100%;
}

@include mobile-xs {
  .hideForMobileXS {
    display: none !important;
  }
}

@include mobile-s {
  .hideForMobileS {
    display: none !important;
  }
}

@include desktop-md {
  .hideForDesktopMd {
    display: none !important;
  }
}

@include desktop-l {
  .hideForDesktopL {
    display: none !important;
  }
}

@include desktop-xl {
  .hideForDesktopXL {
    display: none !important;
  }
}

@include mobile {
  .hideForMobile {
    display: none !important;
  }
}

@include desktop {
  .hideForDesktop {
    display: none !important;
  }
}
