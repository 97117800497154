@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.title3 {
  @include mobile {
    font-size: rem(16);
    line-height: rem(19);
  }

  @include desktop {
    font-size: rem(20);
    line-height: rem(24);
  }

  color: $brown;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  h3,
  p {
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
