@import 'abstracts/functions';

.star {
  display: inline-block;
  margin-left: rem(5);
  font-size: rem(16);
}

.minimized {
  margin-left: 0;
}
