@import 'abstracts/variables';
@import 'abstracts/functions';

.btnTertiary {
  position: relative;
  overflow: hidden;
  padding: rem(14) rem(24);
  border-width: rem(1);
  border-style: solid;
  border-color: $red;
  border-radius: rem(30);
  color: $red;
  font-weight: 500;
  font-size: rem(13);
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  fill: $red;
}

.btnTertiary::before {
  content: '';
  position: absolute;
  z-index: 0;
  display: block;
  transform: scaleX(0);
  opacity: 0;
  transition: all 0.3s ease;
  inset: 0;
}

.btnTertiary::first-letter {
  text-transform: uppercase;
}

.btnTertiary:hover,
.btnTertiary:focus,
.btnTertiary:active {
  color: $light;
  background-color: $red;
  transition: background-color 0.3s ease;
  fill: $light;
}

.btnTertiaryWhite {
  border-color: $light;
  color: $light;
  fill: $light;
}

.btnTertiaryWhite:hover,
.btnTertiaryWhite:focus,
.btnTertiaryWhite:active {
  color: $red;
  background-color: $light;
  fill: $red;
}

.btnTertiaryBrown {
  border-color: $brown;
  color: $brown;
  fill: $brown;
}

.btnTertiaryBrown:hover,
.btnTertiaryBrown:focus,
.btnTertiaryBrown:active {
  color: $light;
  background-color: $brown;
  fill: $light;
}

.btnTertiaryGreen {
  border-color: $green-1;
  color: $light;
  background-color: $green-1;
}

.btnTertiaryGreen:hover,
.btnTertiaryGreen:focus,
.btnTertiaryGreen:active {
  background-color: $green-3;
}

.content {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
}

.iconRight {
  margin-left: rem(10);
  font-weight: 700;
}
