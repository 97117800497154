@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.price {
  font-size: rem(42);
  font-weight: bold;
}

.priceCents {
  top: rem(-8);
  font-weight: 600;
}
