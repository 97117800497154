@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

.row {
  --gutter-x: 1.5rem;
  --gutter-y: 0;

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gutter-y));
  margin-right: calc(-0.5 * var(--gutter-x));
  margin-left: calc(-0.5 * var(--gutter-x));
}
