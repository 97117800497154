@import 'abstracts/variables';
@import 'abstracts/functions';

.btnPrimary {
  position: relative;
  overflow: hidden;
  padding: rem(17) rem(24) rem(14);
  border: 0;
  border-radius: rem(5);
  color: $light;
  font-weight: 700;
  line-height: rem(17);
  text-align: center;
  white-space: nowrap;
  background-color: $red;
  fill: $light;
}

.btnPrimary:not(:disabled) {
  box-shadow: 0 rem(4) 0 $brown-light;
}

.btnPrimary:disabled {
  border: rem(1) solid $red-2;
  color: $light;
  background-color: $red-2;
}

.btnPrimary:not(:disabled)::before {
  content: '';
  position: absolute;
  z-index: 0;
  display: block;
  background-color: $brown-light;
  transform: scaleX(0);
  opacity: 0;
  transition: all 0.3s ease;
  inset: 0;
}

.btnPrimary:not(:disabled):hover,
.btnPrimary:not(:disabled):focus,
.btnPrimary:not(:disabled):active {
  color: $light;
  box-shadow: 0 rem(4) 0 $brown;
  transition: color 0.3s ease, box-shadow 0.3s ease;
}

.btnPrimary:not(:disabled):hover::before,
.btnPrimary:not(:disabled):focus::before,
.btnPrimary:not(:disabled):active::before {
  transform: scaleX(1);
  opacity: 1;
}

.btnPrimaryWhite::before {
  background-color: $red;
}

.btnPrimaryBrown,
.btnPrimaryWhite {
  background-color: $light;
}

.btnPrimaryWhite {
  border: rem(1) solid $red;
  color: $red;
  box-shadow: 0 rem(4) 0 $red;
}

.btnPrimaryBrown {
  border: rem(1) solid $brown;
  color: $brown-light;
  box-shadow: 0 rem(4) 0 $brown-light;
}

.btnPrimaryBrown::before {
  background-color: $brown-light;
}

.btnPrimaryWhite:hover,
.btnPrimaryWhite:focus,
.btnPrimaryWhite:active {
  color: $light;
}

.btnPrimaryBrown:hover,
.btnPrimaryBrown:focus,
.btnPrimaryBrown:active {
  color: $light;
}

.content {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
}

.iconRight {
  margin-left: rem(10);
  font-weight: 700;
}
