@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.25em solid currentcolor;
  border-width: 0.2em;
  border-right-color: transparent;
  border-radius: 50%;
  color: $gray-light-2;
  vertical-align: -0.125em;
  animation: 0.75s linear infinite spinner-border;
}
