@import 'abstracts/variables';
@import 'abstracts/functions';

.btnSecondary {
  position: relative;
  overflow: hidden;
  padding: rem(17) rem(24) rem(14);
  border: rem(1) solid $red;
  border-radius: rem(5);
  color: $red;
  font-weight: 700;
  line-height: rem(17);
  text-align: center;
  white-space: nowrap;
  fill: $red;
}

.btnSecondary::before {
  content: '';
  position: absolute;
  z-index: 0;
  display: block;
  background-color: $red;
  transform: scaleX(0);
  opacity: 0;
  transition: all 0.3s ease;
  inset: 0;
}

.btnSecondary::first-letter {
  text-transform: uppercase;
}

.btnSecondary:hover,
.btnSecondary:focus,
.btnSecondary:active {
  transition: color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s;
}

.btnSecondary:hover::before,
.btnSecondary:focus::before,
.btnSecondary:active::before {
  transform: scaleX(1);
  opacity: 1;
}

.btnSecondaryBrown {
  border: rem(1) solid $brown-light;
  color: $brown-light;
  fill: $brown-light;
}

.btnSecondaryBrown::before {
  background-color: $brown-light;
}

.btnSecondary:hover,
.btnSecondary:focus,
.btnSecondary:active,
.btnSecondaryBrown:hover,
.btnSecondaryBrown:focus,
.btnSecondaryBrown:active {
  color: $light;
}

.btnSecondaryWhite {
  border: rem(1) solid $light;
  color: $light;
  background-color: transparent;
  fill: $light;
}

.btnSecondaryWhite::before {
  background-color: $red;
}

.btnSecondaryWhite:hover,
.btnSecondaryWhite:focus,
.btnSecondaryWhite:active {
  border-color: transparent;
}

.content {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
}

.iconRight {
  margin-left: rem(10);
  font-weight: 700;
}
