@import 'abstracts/variables';
@import 'abstracts/functions';

.title4 {
  color: $brown-light;
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(19);
  text-align: center;
  text-transform: uppercase;

  h4 {
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
