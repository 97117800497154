@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.customSelect {
  @include mobile {
    width: 100%;
  }
}

.customSelect :global(.customSelect__indicator) svg path {
  fill: $brown;
}

.customSelect:global(.customSelect--is-disabled)
  :global(.customSelect__control) {
  @include mobile {
    border: rem(1) solid $brown-2;
    background-color: $brown-2;
  }

  @include desktop {
    background-color: $gray-dark-5;
  }
}

.customSelect :global(.customSelect__control) {
  @include mobile {
    padding: rem(2) rem(18);
  }

  @include desktop {
    padding: rem(2) rem(17);
    border: 0;
  }

  border-radius: rem(3);
  background-color: $light;
}

.customSelect :global(.customSelect__value-container) {
  padding: 0;
}

.customSelect :global(.customSelect__group-heading) {
  @include mobile {
    color: $brown;
    font-size: rem(16);
    line-height: rem(17);
    letter-spacing: rem(0.28);
  }

  @include desktop {
    color: $dark-1;
    font-size: rem(14);
    line-height: rem(14);
    letter-spacing: rem(0.24);
  }

  padding-right: rem(8);
  padding-left: rem(8);
  font-weight: 700;
  text-transform: uppercase;
}

.customSelect :global(.customSelect__single-value),
.customSelect :global(.customSelect__placeholder),
.customSelect :global(.customSelect__option) {
  @include mobile {
    color: $brown;
    font-size: rem(14);
    line-height: rem(17);
    letter-spacing: rem(0.28);
  }

  @include desktop {
    color: $dark-1;
    line-height: rem(14);
    font-size: rem(12);
    letter-spacing: rem(0.24);
  }

  font-weight: 500;
  text-transform: uppercase;
}

.customSelect :global(.customSelect__option):not(:last-child) {
  border-bottom: rem(1) solid $gray-medium;
}

.customSelect :global(.customSelect__option--is-focused),
.customSelect :global(.customSelect__option):not(:hover) {
  background-color: $light;
}

.customSelect :global(.customSelect__option--is-selected):not(:hover),
.customSelect :global(.customSelect__option):active:not(:hover) {
  background-color: $gray-light-5;
}

.customSelect :global(.customSelect__option):hover {
  color: $light;
  background-color: $green-2;
}

.customSelect :global(.customSelect__indicator-separator) {
  display: none;
}

.customSelect :global(.customSelect__menu) {
  top: 96%;
  z-index: 100;
  border-radius: 0;
  margin-top: 0;
  box-shadow: none;
}
