@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.title2 {
  @include mobile {
    font-size: rem(18);
    line-height: rem(22);
  }

  @include desktop {
    font-size: rem(22);
    line-height: rem(27);
  }

  color: $red;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  h2 {
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
